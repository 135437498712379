<template>
  <div class="courseware_list_container">
    <div class="courseware_list_search">
      <div class="courseware_list_select">
        <el-select
          v-model.trim="search.periods"
          placeholder="Select"
          popper-class="select_option"
          @change="searchData"
        >
          <el-option label="全部" value="" />
          <el-option
            v-for="item in periodsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="courseware_list_search_input">
        <el-input
          v-model.trim="search.key_word"
          placeholder="请输入关键词"
          @keyup.enter="searchData"
          @blur="searchData"
        >
          <template #suffix>
            <i class="el-icon-search" @click="searchData"></i>
          </template>
        </el-input>
      </div>
    </div>
    <div class="courseware_list_conent">
      <div class="courseware_list_conent_title">课件列表</div>

      <courseware :searchDate="search" ref="listScrollRef"></courseware>
    </div>
  </div>
</template>

<script>
import courseware from "./components/list.vue";

export default {
  name: "list",
  components: {
    courseware,
  },
  data() {
    return {
      search: { key_word: "", periods: "", page: 1, pagesize: 20 },
      periodsList: [],
      data: [
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 1, location: 1 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 1, location: 2 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 1, location: 3 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 0 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 0 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 0 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 0 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 0 },
        { title_head: "胃癌肺转移:生存预测新模型", emphasis: 0 },
      ],
      timer: null,
      rotate: false,
    };
  },
  created() {
    this.getPeriodData();
  },
  mounted() {
    this.searchData();
  },
  methods: {
    searchData() {
      console.log("this.$refs.listScrollRef :>> ", this.$refs.listScrollRef);
      this.$refs.listScrollRef.loadData(this.search);
    },
    getPeriodData() {
      this.$axios
        .get(this.$urls.literature.periodsList)
        .then((res) => {
          this.periodsList = res.data.map((num) => {
            let year = num.split("-")[0];
            let month = parseInt(num.split("-")[1]);
            return {
              value: num,
              label: `${year}-第${month}期`,
              // label: `${year}-第${this.$tools.intToChinese(month)}期`,
            };
          });
          console.log(" this.periodsList :>> ", this.periodsList);
        })
        .catch(() => {});
    },
    handleRefresh() {
      if (this.timer) return;
      this.rotate = true;
      //   this.$refs.listScrollRef.scroll.scrollTo(0, 0);
      //   this.$refs.listScrollRef.pullingDownHandler(true);
      this.timer = setTimeout(() => {
        this.rotate = false;
        this.timer = null;
      }, 1000);
    },
    handleDetail(item) {
      this.$router.push({
        path: "/articleListItem",
        query: {
          id: item.id,
        },
      });
    },
    handleLike() {},
    goToInfo() {},
  },
};
</script>

<style >
.courseware_list_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 21px;
}
.courseware_list_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courseware_list_search .courseware_list_search_input .el-input {
  width: 211px;
  height: 30px;
  line-height: normal !important;
}
.courseware_list_search_input input {
  height: 30px;
  line-height: normal !important;
  font-size: 14px !important;
  border-radius: 2rem;
}
.courseware_list_search_input input::-webkit-input-placeholder {
  line-height: normal !important;
  font-size: 14px !important;
}
.courseware_list_search .el-input__suffix {
  font-size: initial;
  display: flex;
  align-items: center;
  right: 3.5vw;
  line-height: 30px;
}
.courseware_list_search .el-input__inner {
  height: 30px;
  border-radius: 30px;
}
.courseware_list_search .el-input__suffix-inner,
.courseware_list_search .el-select .el-input .el-select__caret {
  height: 30px;
  line-height: 30px;
}
.courseware_list_select .el-select {
  width: 140px !important;
}

.courseware_list_conent {
  background: #fff;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}

.courseware_list_conent_title {
  font-weight: bold;
  font-size: 14px;
  color: #009d7d;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
}
</style>